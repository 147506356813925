const doc = document.documentElement;
const body = document.body;

const getVar = (name, el = doc) => {
  if (!el) {
    console.error("element to get var from not found");
  }
  const styles = getComputedStyle(el);
  if (styles.getPropertyValue(name) && styles.getPropertyValue(name).length > 0) {
    return styles.getPropertyValue(name);
  }
  console.error(`variable '${name}' not found`);
};

const getCssVars = () => {
  const vars = getVar("--color-vars");
  if (vars) {
    const varNames = vars.split(",").map((name) => `--${name.trim()}`);

    const cssVars = varNames.reduce((acc, name) => {
      if (getVar(name)) {
        acc[name] = getVar(name);
      } else {
        console.error(`Could not get var ${name}.`);
      }
      return acc;
    }, {});

    return cssVars;
  }
  console.error(`Could not find varNames.`);
};

function shadeHexColor(color, percent) {
  var f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

const setVar = (name, val, el = body) => el.style.setProperty(name, val);

const rgbFromHex = (hex) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return { r, g, b };
};

const getContrastYIQ = (hexcolor) => {
  const thresholt = 135;
  const { r, g, b } = rgbFromHex(hexcolor);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= thresholt ? "#000000" : "#ffffff";
};

const createContentColors = (varName, color) => {
  const contrastColor = getContrastYIQ(color);
  setVar(`${varName}-content`, contrastColor);
  setVar(`${varName}-content-i`, getContrastYIQ(contrastColor));
};

const getContentColors = () => {
  Object.keys(getCssVars()).forEach((name) => {
    const color = getCssVars()[name];
    createContentColors(name, color);

    if (name == "--primary" || name == "--secondary") {
      const shade1 = shadeHexColor(color, -0.5);
      const shade2 = shadeHexColor(color, -0.75);
      setVar(`${name}-shade-1`, shade1);
      setVar(`${name}-shade-2`, shade2);
      createContentColors(`${name}-shade-1`, shade1);
      createContentColors(`${name}-shade-2`, shade2);
    }
  });
};

const observer = new MutationObserver((mutationList) => {
  for (const mutation of mutationList) {
    if (mutation.type === "attributes") {
      getContentColors();
    }
  }
});

try {
  if (doc && body) {
    const event = new CustomEvent("themeDocLoaded", document);
    window.parent.document.dispatchEvent(event);
    observer.observe(doc, { attributes: true });
    getContentColors();
  } else {
    console.error("problem with color contrast script");
  }
} catch (error) {}
